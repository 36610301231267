<template>
    <form class="base-form" @submit.prevent="handleSubmit" :class="form_class">
        <div class="form-rows">
            <div :class="{row: !row.title}" v-for="(row,index) in rows" :key="index">
                <slot v-if="row.custom === true" :name="row.slot_name"></slot>
                <h4 v-else-if="row.title" class="title">{{ row.title }}</h4>
                <div v-else-if="row.divider" class="divider"></div>
                <div v-else>
                    <div class="col-label" >
                        <div v-for="(label,label_index) in row.labels" :key="label_index" :class="label_class(row,label_index)">
                            {{ label }}
                        </div>
                    </div>
                    <div class="col-input">
                        <div class="cell-wrap" v-for="(cell,cell_index) in row.items" :key="cell_index" :class="[cell.class,{ 'is-invalid': !getErrors(cell),'required': cell.required }]" :title="cell.title" >
                            
                            <div class="cell cell-label" v-if="cell.type === 'label'"><BaseIcon v-if="cell.icon" :class="cell.icon()" />{{ cell.content() }}</div>
                            <div class="cell cell-info" v-else-if="cell.type === 'info'">{{ models[cell.key].value  }}</div>
                            <textarea 
                                class="cell cell-textarea" 
                                v-else-if="cell.type === 'textarea'" 
                                v-model.lazy="models[cell.key].value" 
                                :aria-label="cell.title" 
                            />
                            
                            <select v-else-if="cell.type === 'select'" class="cell cell-select" v-model.lazy="models[cell.key].value" :required="cell.required === true">
                                <option class="option" v-for="(option,index) in cell.options()" :key="index" :value="option.code" :title="option.title">
                                    {{ option.title }}
                                </option>
                            </select>

                            <div class="file-area" v-else-if="cell.type === 'file'">
                                <input 
                                        class="cell cell-file-input" 
                                        :id="getInputId(cell)" 
                                        
                                        :type="cell.type" 
                                        :name="cell.key" 
                                        :aria-label="cell.title" 
                                        v-model.lazy="models[cell.key].value"
                                        multiple
                                        required
                                    />
                                <div class="file-dummy">
                                    <div class="success">{{$t("controls.files_selected")}}</div>
                                    <div class="default">{{$t("controls.select_files")}}</div>
                                </div>
                            </div>
                            
                            <BaseButton v-else-if="cell.type === 'button'"  class="cell cell-button" :title="cell.title" @click="cell.action()" type="button">
                                {{ cell.content() }}
                            </BaseButton>

                            
                            <RouterLinkLocal  v-else-if="cell.type === 'link'"  target="_blank" class="cell cell-link" :link="cell.link()" :title="cell.title" >
                                {{ cell.content() }}
                            </RouterLinkLocal>

                            <input 
                                class="cell cell-input" 
                                :id="getInputId(cell)" 
                                v-else-if="cell.type === 'checkbox'"
                                :type="cell.type" 
                                :name="cell.key" 
                                :aria-label="cell.title" 
                                v-model.lazy="models[cell.key].value"
                                true-value="1"
                                false-value="0"
                            />
                            <input 
                                class="cell cell-input" 
                                :id="getInputId(cell)" 
                                v-else  
                                :type="cell.type" 
                                :name="cell.key" 
                                :aria-label="cell.title" 
                                v-model.lazy="models[cell.key].value"
                                :required="cell.required === true"
                            />
                            <BaseIcon class="error" v-if="getErrors(cell)" />
                            <BaseIcon class="orange_star" v-else-if="cell.required" />
                            <label v-if="cell.label" :for="getInputId(cell)">{{ cell.label }}</label>
                            <div v-if="getErrors(cell)" class="invalid-feedback">
                                <div v-for="(error,error_index) in getErrors(cell)" :key="error_index">
                                    {{ error }}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <slot name="rows_after" />
        <div class="buttons-block" v-if="hasButtons">
            <BaseButton type="button" class="btn-danger" @click="formActions.cancelForm" v-if="cancelButton !== ''">
                {{ cancelButton }}
            </BaseButton>
            <BaseButton type="submit" class="btn-success accept-button"  v-if="acceptButton !== ''">
                {{ acceptButton  }}
            </BaseButton>
            <BaseButton type="button" class="btn-danger delete-button"  v-if="deleteButton" @click="toConfirm = true">
                <BaseIcon class="trash_white" />
            </BaseButton>
            <Confirmation 
                :opened="toConfirm" 
                @close="toConfirm=false" 
                @confirmed="formActions.deleteItem"
            />
        </div>
    </form>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';

export default {
    name: "BaseForm",
    components: {
        Confirmation: defineAsyncComponent(() => import(/* webpackChunkName: "confirmation" */'@common/components/base/Confirmation')),
    },
    props: {
        form_class: {
            type: String,
            default: () => ""
        },
        cancelButton: {
            type: String,
            default: () => {
                const i18n = useI18n();

                return i18n.t('controls.cancel');
            }
        },
        acceptButton: {
            type: String,
            default: () => {
                const i18n = useI18n();

                return i18n.t('controls.confirm');
            }
        },
        deleteButton: {
            type: Boolean,
            default: () => false
        },
    },
    setup(props){

        const { 
            rows,
            handleSubmit,
            formActions,
            models,
            getErrors,
            toConfirm,
        } = useFormData();

        const label_class = (row,index) => {
            if(!row.labels_class || row.labels_class.length <= index){
                return "";
            }
            return row.labels_class[index];
        }      

        const hasButtons = computed(() => props.cancelButton || props.acceptButton);

        const getInputId = (cell) => {
            let key = cell.key.replace("[]", "");
            return 'input_'+key;
        };


        return {
            handleSubmit,
            models,
            label_class,
            formActions,
            hasButtons,
            getInputId,
            rows,
            getErrors,
            toConfirm,
        }
    }

}
</script>

<style scoped>
    .cell-wrap{
        position: relative;
        margin-left: 0.5rem;
        /* height: 1.2rem; */
        width: 7rem;
    }
    .cell-wrap>label,.invalid-feedback{
        font-size: 0.8rem;
    }
    .invalid-feedback{
        color: #b96825;
        padding: 0.3rem 0.3rem 0 0.3rem;
    }
    .cell{
        min-height: 1.2rem;
        width: 100%;
        height: 100%;
        display: block;
        line-height: 1.2rem;        
        margin: 0;
    }
    .cell-info, .cell-label{
        font-size: 0.8rem;
        /* border: 1px dotted black;
        border: 1px dotted var(--dark-color); */
        /* white-space: nowrap; */
        padding: 0 0.3rem;
    }
    .row:hover,.row:focus-within{
        background: #e9fff3;
    }
    .row{
        padding: 0.2rem;
    }
    
    /* .module-form select,.module-form input,.module-form .base-table-cell-info{
        width: 6rem;
    }
    .module-form .w-xs>*{
        width: 1rem;
    }
    .module-form .w-xl>*{
        width: 10rem;
    }
    .module-form .w-xxl>*{
        width: 15rem;
    } */

    .base-loader,.icon.error,.icon.orange_star{
        position: absolute;
        right: 0.1rem;
        top: 0.1rem;
        height: 1rem;
        width: 1rem;
        margin: 0;
    }
    .icon.orange_star{
        width: 0.7rem;
    }
    .required .cell-select {
        width: calc(100% - 1rem);
    }


    .header{
        padding: 0.5rem 1rem;
        background-color: var(--primary-color);
        margin: 0;
        box-shadow: 0px 1px 3px 1px grey;
        font-size: 1.5rem;
    }

    .cell-textarea {
        resize: vertical;
    }

.col-label {
    float: left;
    width: 30%;
    /* margin: 0.1rem 0; */
    font-size: 0.8rem;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
}
.col-label>div{
    flex: 1 1 0px;
}

.col-input {
    float: left;
    width: 70%;
    /* margin: 0.1rem 0; */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.col-input{
    display: flex;
    align-items: flex-start;
}

.w-stretch{
    flex-grow: 2;
}
.w-xxs{
    width: 1rem;
}
.w-xs{
    width: 3rem;
}
.w-s{
    width: 5rem;
}
.w-l{
    width: 10rem;
}
.w-xl{
    width: 15rem;
}
.w-fit{
    width: fit-content;
}
.h-2rows{
    height: 2.4rem;
}

.strong{
    font-weight: 700;
}

.form_small .form-rows{
    max-width: 30rem;
    margin: auto;
}

.buttons-block{
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.buttons-block>button{
    margin: 0 0.5rem;
    min-width: 10rem;
}
.btn .icon{
    margin: 0;
    width: 1.2rem;
    height: 1.2rem;
}
.buttons-block>button.delete-button{
    min-width: initial;
}
.accept-button{
    flex-grow: 1;
}

.file-area{
    position: relative;
    height: 10rem;
}
.aligh-right{
    text-align: right;
}

.cell-file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
}
.file-dummy {
    width: 100%;
    height: 100%;
    padding: 3rem;
    background: rgba(255,255,255,0.2);
    border: 2px dashed rgb(16, 82, 46,0.5);
    text-align: center;
    transition: background 0.3s ease-in-out;
    
  }
  
   .file-dummy .success {
      display: none;
    }
    .cell-file-input:hover + .file-dummy {
    background: rgba(255,255,255,0.1);
  }
    
  .cell-file-input:valid + .file-dummy {    
      border-color: rgb(9 165 80);
    background-color: rgb(231 255 242);
  }

  .cell-file-input:valid + .file-dummy .success {
      display: inline-block;
    }
  .cell-file-input:valid + .file-dummy .default {
      display: none;
    } 

.title{
    text-align: center;
    overflow: hidden;
    margin: 1rem 0 0.5rem 0;
}
.title:before,
.title:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.title:before {
  right: 0.5em;
  margin-left: -50%;
}

.title:after {
  left: 0.5em;
  margin-right: -50%;
}
.divider{
    width: 100%;
    height: 0;
    border-bottom: 1px solid black;
}

@media screen and (max-width: 600px) {
  .col-label, .col-input, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
  .cell-file-input {
    padding: 5rem 2rem;
}
  
    .buttons-block>button{
        margin: 0 0.5rem;
        min-width: 30%;
    }
}

</style>
