<template>
    <div>
        <h2 class="form-title" v-if="data.title">
            {{data.title}}
        </h2>
        <BaseForm 
            :form_class="''" 
            :cancelButton="''"
            :acceptButton="''"
        >
        <template v-slot:rows_after>
            <div class="buttons-block" v-for="(button,index) in data.buttons" :key="index">
                <BaseButton :class="button.class" :type="button.type">{{ button.text }}</BaseButton>
            </div>
        </template>
        </BaseForm>
    </div>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm'; 
import useFormData from '@common/composable/BaseFormData';
import {  watchEffect } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "FormCell",
    components: { 
        BaseForm,     
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        content: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const { data,setRows,store_action,formInit,code,formActions } = useFormData();
        
        const router = useRouter();

        const setPreset = () => {
            if(!router.currentRoute.value.query || !router.currentRoute.value.query.preset){
                return;
            }
            if(!props.data.presets || !props.data.presets[router.currentRoute.value.query.preset]){
                return;
            }
            let preset = props.data.presets[router.currentRoute.value.query.preset];
            for(let cellname in preset){
                if(!Object.prototype.hasOwnProperty.call(preset,cellname)) {
                    continue;
                }
                data.value[cellname] = preset[cellname];
            }
        };
        watchEffect(() => {
            if(!props.data || !props.data.rows){
                return;
            }
            formInit();
            store_action.value = 'page/submitForm';
            setRows(props.data.rows);
            code.value = props.content.code;
            formActions.value.afterSubmit = () => {
                data.value = {};
            };
            
            setPreset();
        });
        



        return {
        };
    }
}
</script>

<style scoped>

</style>