
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import config from 'config'
import { load } from 'recaptcha-v3'

const data = ref({});

const rows = ref({});

const store_action = ref('');

const formActions = ref({});

const submitted = ref(false);

const code = ref(null);

const errors = ref({});

function isValidDate(s) {
    let separators = ['\\.', '\\-', '\\/'];
    let bits = s.split(new RegExp(separators.join('|'), 'g'));
    let d = new Date(bits[2], bits[1] - 1, bits[0]);
    return d.getFullYear() == bits[2] && d.getMonth() + 1 == bits[1];
} 

export default function() {
    submitted.value = false;
    
    const store = useStore();
    const router = useRouter();
   
    const toConfirm = ref(false);

    const formInit = () => {
        rows.value = {};
        store_action.value = '';
        formActions.value = {
            cancelForm: () => {
                router.go(-1);
            },
            afterSubmit: () => {
                router.go(-1);
            },
            deleteItem: () => {
                router.go(-1);
            }
        };
        code.value = null;
        toConfirm.value = false;
    
    }
    const handleSubmit = async () => {
        if(validate_form()){
            if(config.recaptchaKey && process.env.NODE_ENV === 'production'){
                const recaptcha =  await load(config.recaptchaKey);
                // Execute reCAPTCHA with action "login".
                const token = await recaptcha.execute('login');
                data.value["reCaptcha"] = token;
            }
            if(store_action.value){
                store.dispatch(store_action.value,{data: data.value,code: code.value});
            }
            
            formActions.value.afterSubmit();
            
        }
    }

    const i18n = useI18n();

    const loadErrors = (cell) => {
        if(!Object.prototype.hasOwnProperty.call(cell,'key')){
            return;
        }

        if(cell.required && !data.value[cell.key] && data.value[cell.key] !== 0){
            errors.value[cell.key] = [
                i18n.t('form.item_required', { item: cell.title })
            ]
            return;
        }

        if(!Object.prototype.hasOwnProperty.call(data.value,cell.key)){ // value not required and not filled
            return;
        }
        
        if(Object.prototype.hasOwnProperty.call(cell,'rules')){
            let err = [];
            let value = data.value[cell.key];
            if(Object.prototype.hasOwnProperty.call(cell.rules,'lengthMin')){
                if(value.length < cell.rules.lengthMin){
                    err.push(i18n.t('form.item_invalid_min_length', { item: cell.title,limit: cell.rules.lengthMin}))
                }
            }
            if(Object.prototype.hasOwnProperty.call(cell.rules,'lengthMax')){
                if(value.length < cell.rules.lengthMax){
                    err.push(i18n.t('form.item_invalid_max_length', { item: cell.title,limit: cell.rules.lengthMax}))
                }
            }
            if(Object.prototype.hasOwnProperty.call(cell.rules,'isDate')){
                if(!isValidDate(value)){
                    err.push(i18n.t('form.item_invalid_date', { item: cell.title}))
                }
            }

            if(err.length > 0){
                errors.value[cell.key] = err;
                return;
            }
        }

        if(Object.prototype.hasOwnProperty.call(cell,'errors')){
            let err = cell.errors(data.value);
            if(err){
                errors.value[cell.key] = err;
            }
            return;
        }

        // console.log(cell.key);
    }
    const hasNoErrors = computed(() => {
        const obj = errors.value;
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    });
    const validate_form = () => {
        submitted.value = true;
        errors.value = {};
        // console.log(data.value);
        for(let row of rows.value){
            // console.log(row);
            if(!Object.prototype.hasOwnProperty.call(row,'items')){
                    continue;
                }
            for(let cell of row.items){
               loadErrors(cell);
            }
        } 
        // console.log(errors.value);
        return hasNoErrors.value;
    }

    const getErrors = (cell) => {
        if(!Object.prototype.hasOwnProperty.call(cell,'key')){
            return false;
        }
        if(!Object.prototype.hasOwnProperty.call(errors.value,cell.key)){
            return false;
        }
        if(errors.value[cell.key].length <= 0){
            return false;
        }
        return errors.value[cell.key];
    }

    
    const getter_setter = (key) => {
        return {
            get () {
                return data.value[key];
            },
            set (newValue) {
                data.value[key] = newValue;
            }
        }
    }

    const getModel = (cell) => {
        if(!Object.prototype.hasOwnProperty.call(cell,'key')){
            return "";
        }
        const key = cell.key;
        if(!Object.prototype.hasOwnProperty.call(data.value,key)){
            data.value[key] = '';
        } 
        return computed( getter_setter(key));
    }
    
    const models = computed(() => {
        let temp = {};
        rows.value.forEach(row => {
            if(!Object.prototype.hasOwnProperty.call(row,'items')){
                    return;
                }
            row.items.forEach(cell => {
                let model = getModel(cell);
                if(model === false){
                    return;
                }
                temp[cell.key] = model;
            })  
        });
        return temp;
    })   


    const data_valid = (cell) => {
        if(!Object.prototype.hasOwnProperty.call(cell,'key')){
            return true;
        }
        let key = cell.key;
        if(submitted.value && cell.required && models.value[key] && !models.value[key].value){
            return false;
        }
        return true;
    }

    const setRows = (newRows) => {
        rows.value = newRows;
    }

    return {
        data,
        validate_form,
        rows,
        setRows,
        submitted,
        handleSubmit,
        store_action,
        formActions,
        formInit,
        code,
        models,
        data_valid,
        getErrors,
        toConfirm,
    }    
}
